<div
  class="relative flex flex-col space-y-2"
  [attr.tabindex]="disabled ? -1 : 0"
  [ngClass]="{ 'opacity-70': disabled }"
>
  <div class="absolute inset-0 z-10 cursor-default rounded-lg" *ngIf="disabled"></div>
  <mat-form-field appearance="fill" class="mat-form-field-compact mat-form-field-plain">
    <input
      type="search"
      matInput
      [matAutocomplete]="userAutocomplete"
      [placeholder]="('utils.search-user-or-groups' | translate) + (required ? ' *' : '')"
      [formControl]="search"
      [required]="required"
    />
    <mat-icon matSuffix>search</mat-icon>
    <mat-autocomplete #userAutocomplete="matAutocomplete" (optionSelected)="onOptionSelect($event)">
      <ng-container *ngIf="this.searched$ | async as searched">
        <mat-option *ngFor="let entity of searched; trackBy: trackId" [value]="entity">
          <ng-container
            *ngTemplateOutlet="
              entity.type === 'user' ? userTemplate : entity.type === 'group' ? groupTemplate : null;
              context: { entity: entity.entity }
            "
          ></ng-container>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <ng-container *ngIf="selected$ | async as items">
    <div class="mat-bg flex flex-col space-y-2 rounded-lg px-2.5 py-4" *ngIf="items.length > 0; else noSelectedItems">
      <ng-container *ngFor="let item of items; trackBy: trackId">
        <div
          (contextmenu)="
            openEntityContextMenu($event, entityContextMenuTrigger, {
              entity: item
            })
          "
        >
          <ng-container
            *ngTemplateOutlet="
              item.type === 'user' ? userTemplate : item.type === 'group' ? groupTemplate : null;
              context: {
                entity: item.entity,
                showDelete: true,
                showGroupUsers: true
              }
            "
          ></ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
<ng-template #userTemplate let-user="entity" let-showDelete="showDelete">
  <div class="mat-typography group relative flex h-10 select-none items-center space-x-3">
    <div class="h-10 w-10">
      <app-user-avatar [userId]="user.id" size="100%" class="flex-shrink-0 rounded-full"></app-user-avatar>
    </div>
    <div class="flex flex-1 flex-col space-y-0 truncate">
      <div class="truncate">{{ user.name }}</div>
      <div *ngIf="user.email as email" class="mat-hint truncate text-sm">
        {{ email }}
      </div>
    </div>
    <ng-container
      *ngTemplateOutlet="
        showDelete ? deleteEntityTemplate : null;
        context: { entity: { entity: user, type: 'user', id: user.id } }
      "
    ></ng-container>
  </div>
</ng-template>
<ng-template #groupUsersTemplate let-user="user">
  <div class="mat-typography group relative flex h-10 select-none items-center space-x-3">
    <div class="h-10 w-10">
      <app-user-avatar [userId]="user.id" size="100%" class="flex-shrink-0 rounded-full"></app-user-avatar>
    </div>
    <div class="flex flex-1 flex-col space-y-0">
      <div>{{ user.name }}</div>
      <div *ngIf="user.email as email" class="mat-hint text-sm">
        {{ email }}
      </div>
    </div>
  </div>
</ng-template>
<ng-template #groupTemplate let-group="entity" let-showDelete="showDelete" let-showGroupUsers="showGroupUsers">
  <tg-user-group-view
    [group]="group"
    [showDelete]="showDelete"
    [showGroupUsers]="showGroupUsers"
    [deleteEntityTemplate]="deleteEntityTemplate"
    [groupUsersTemplate]="groupUsersTemplate"
  ></tg-user-group-view>
</ng-template>
<div
  #entityContextMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="entityContextMenu"
  style="visibility: hidden; position: fixed"
  [style.left]="entityContextMenuPosition.x"
  [style.top]="entityContextMenuPosition.y"
></div>
<mat-menu #entityContextMenu="matMenu">
  <ng-template matMenuContent let-entity="entity">
    <div class="flex flex-col">
      <button
        mat-menu-item
        (click)="this.removeEntity(entity)"
        class="text-warn"
        translate
        *ngIf="{
          remove: entity.type === 'user' ? 'user.remove' : entity.type === 'group' ? 'workspace.groups.remove' : null
        } as lang"
      >
        {{ lang.remove | translate }}
      </button>
    </div>
  </ng-template>
</mat-menu>
<ng-template #noSelectedItems>
  <ng-container *ngIf="!required">
    <span class="mat-bg mat-hint flex h-16 flex-col items-center justify-center rounded-lg text-center">
      {{ 'utils.no-users-or-groups-selected' | translate }}
    </span></ng-container
  >
</ng-template>
<ng-template #deleteEntityTemplate let-entity="entity">
  <button mat-icon-button [matMenuTriggerFor]="entityContextMenu" [matMenuTriggerData]="{ entity: entity }">
    <mat-icon>more_vert</mat-icon>
  </button>
</ng-template>
